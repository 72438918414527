import i18n from '~/i18n'

export const RETAIL_MEDIA_FILTER_KEY = 'retailMedia'

export const RETAIL_MEDIA_FILTER_INITIAL_STATE: RetailMediaFilterGroup = {
  query: null,
  campaignName: null,
  adType: null,
  publisher: null,
  status: null,
  walletType: null,
  queryFilterWallet: null
}

export const CAPMPAIGNS_FILTERS_KEYS = {
  query: 'retailMedia.query',
  campaignName: 'retailMedia.campaignName',
  productSKU: 'retailMedia.productSKU',
  adType: 'retailMedia.adType',
  adStatus: 'retailMedia.adStatus',
  publisher: 'retailMedia.publisher',
  advertiser: 'retailMedia.advertiser',
  status: 'retailMedia.status',
  walletType: 'retailMedia.walletType',
  queryFilterWallet: 'retailMedia.queryFilterWallet'
} as const

export const campaignStatuses = [
  'partial_running',
  'running',
  'out_of_budget',
  'paused',
  'updating',
  'pending_review',
  'pending',
  'scheduled',
  'approved',
  'awaiting_approval', // confirmar status
  'approval_rejected', // confirmar status
  'rejected',
  'finished',
  'canceled'
] as RetailMediaCampaignStatuses[]

export const liveCampaignStatuses = [
  'partial_running',
  'running',
  'out_of_budget',
  'paused',
  'updating',
  'pending_review',
  'pending',
  'scheduled',
  'approved',
  'awaiting_approval' // confirmar status
] as RetailMediaCampaignStatuses[]

const label = {
  pending_review: i18n.t('rm:pending_review'),
  approved: i18n.t('rm:approved'),
  pending: i18n.t('rm:pending'),
  running: i18n.t('rm:running'),
  partial_running: i18n.t('rm:partialRunning'),
  updating: i18n.t('rm:updating'),
  paused: i18n.t('rm:Paused', { context: 'female' }),
  scheduled: i18n.t('rm:scheduled'),
  finished: i18n.t('rm:finished'),
  out_of_budget: i18n.t('rm:outOfBudget'),
  canceled: i18n.t('rm:canceled'),
  rejected: i18n.t('rm:rejected'),
  approval_rejected: i18n.t('rm:approvalRejected'),
  awaiting_approval: i18n.t('rm:awaitingApproval')
} as { [key in RetailMediaCampaignStatuses]?: string }

export const CAPMPAIGNS_STATUSES = campaignStatuses.map(item => ({
  value: item,
  label: label?.[item] || item
}))

export const adsStatuses = [
  'enabled',
  'invalid_cost',
  'paused',
  'stock_out'
] as RetailMediaAdsStatuses[]

export const labelAdsStatus: {
  [key in RetailMediaAdsStatuses]: { label: string; color: string }
} = {
  pending_review: {
    label: i18n.t('rm:pending_review'),
    color: 'warning'
  },
  rejected: {
    label: i18n.t('rm:rejected'),
    color: 'danger'
  },
  enabled: {
    label: i18n.t('rm:ready'),
    color: 'success'
  },
  paused: {
    label: i18n.t('rm:Paused'),
    color: 'warning'
  },
  invalid_cost: {
    label: i18n.t('rm:adsStatus.invalidCost'),
    color: 'warning'
  },
  stock_out: {
    label: i18n.t('rm:stockOut'),
    color: 'warning'
  }
}

export const ADS_STATUSES = adsStatuses.map(item => ({
  value: item,
  label: labelAdsStatus?.[item].label || item
}))

export const labelTypes: { [key in TransactionTypes]: string } = {
  credit: i18n.t('rm:credit'),
  debit: i18n.t('rm:debit'),
  refund: i18n.t('rm:refund')
}

const walletTypes = Object.keys(labelTypes) as TransactionTypes[]

export const WALLET_TYPE_OPTIONS = walletTypes.map(
  (item: TransactionTypes) => ({
    value: item,
    label: labelTypes?.[item] || item
  })
)

export const labelSubtypes: { [key in TransactionSubtypes]: string } = {
  bonus: i18n.t('rm:bonus'),
  common: i18n.t('rm:common')
}

type TransactionStatus = {
  [key in WalletPaymentStatus]: {
    label: string
    template: StatusTemplates
  }
}

export const transactionStatus: TransactionStatus = {
  payment_authorized: {
    label: i18n.t('rm:payment.status.authorized'),
    template: 'success_alert'
  },
  payment_approved: {
    label: i18n.t('rm:payment.status.approved'),
    template: 'success'
  },
  payment_refused: {
    label: i18n.t('rm:payment.status.refused'),
    template: 'danger'
  },
  payment_refunded: {
    label: i18n.t('rm:payment.status.refunded'),
    template: 'info'
  },
  waiting_payment: {
    label: i18n.t('rm:payment.status.waitingPayment'),
    template: 'warning'
  },
  payment_canceled: {
    label: i18n.t('rm:payment.status.canceled'),
    template: 'danger'
  }
}
