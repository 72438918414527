import { types } from './constants'

// Checking Account - List
export const setPageListAds = (value: number) => ({
  type: types.listSetPage,
  payload: value
})

export const setPerPageListAds = (value: number) => ({
  type: types.listPerPage,
  payload: value
})

export const setSortListAds = (value: OnSortProps) => ({
  type: types.listSort,
  payload: value
})

export default {
  setPage: setPageListAds,
  setPerPage: setPerPageListAds,
  setSorting: setSortListAds
}
